import { graphql } from "gatsby"
import * as React from "react"
import { Layout } from "../components/Layout"
import { BlockContent } from "../components/BlockContent"
import { createLocaleTextGetter } from "../../localize"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { LocaleContext } from "../components/LocaleContext"

export const query = graphql`
  fragment blockBorderFragment on SanityBlockBorder {
    title {
      ...translatable
    }
    titlePosition
    style {
      variant
      corner {
        asset {
          url
        }
      }
      color {
        hex
      }
    }
  }
  fragment premierePageBlocksFragment on SanityPremierePage {
    blocks {
      __typename
      ...videoFragment
      ...carouselFragment
      ...promoFragment
      ...verticalGroupFragment
      ...horizontalGroupFragment
      ...textBlockFragment
      ...richTextBlockFragment
      ...imageBlockFragment
      ...overlineFragment
      ...formFragment
      ...navigationFragment
      ...linkFragment
    }
  }

  query ($regex: String!) {
    sanityPremierePage(_id: { regex: $regex }) {
      _id
      ...premierePageBlocksFragment
    }
    allStock {
      nodes {
        product
        venues
      }
    }
  }
`

const PremierePageWrapper = styled.div`
  ${tw`w-full flex items-center justify-center`}
`

const Container = styled.div`
  width: 100%;
  max-width: 840px;
  @media screen and (min-width: 840px) {
    border: 3px solid rgb(201, 150, 76);
    border-top: 0;
  }
`

const PremierePage = ({ pageContext, data, location }) => {
  const getLocalizedContent = createLocaleTextGetter(pageContext.locale)
  const { sanityPremierePage } = getLocalizedContent(data)

  return (
    <LocaleContext.Provider value={pageContext.locale}>
      <Layout location={location}>
        <PremierePageWrapper>
          <Container>
            <BlockContent
              blocks={sanityPremierePage.blocks}
              blockWrapper={({ children }) => <div>{children}</div>}
            />
          </Container>
        </PremierePageWrapper>
      </Layout>
    </LocaleContext.Provider>
  )
}

export default PremierePage
